import * as React from 'react';
import styles from './index.module.scss';
import Navbar from '../../components/Navbar';
import Head from '../../components/Head';
import Footer from '../../components/Footer';
import LearnMore from '../../components/LearnMore';
import Hero from '../../components/Hero';
import heroImage from '../../../assets/images/solutions/restaurants.svg';
import verificationImage from '../../../assets/images/verification.svg';
import classNames from 'classnames';
import identityImage from '../../../assets/images/identity.svg';
import celebrateImage from '../../../assets/images/celebrate.svg';

const UseCaseRestaurants: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.Solution}>
      <Head title='Opinous for Restaurants' />
      <Navbar />
      <Hero graphic={heroImage} narrow={true}>
        <h1>
          <span className={styles.For}>Opinous For</span> Restaurants
        </h1>
        <h2>
          Reviews are an essential part of SEO for restaurants with the way your business ranks for
          keywords determined in large part by the contents of reviews.
          <br />
          <br />
          We know that as a restaurant collecting email addresses from your customers is difficult
          and that passively asking for reviews doesn't work.
          <br />
          <br />
          Opinous makes it easy to collect review content from all of your customers with our suite
          of products and integrations designed specifically for restaurants.
        </h2>
      </Hero>
      <section className={styles.Story}>
        <div className={styles.SectionTitle}>
          <h2>Drive new customers to your restaurant with Opinous</h2>
        </div>
        <div className={styles.Part}>
          <div className={styles.Image}>
            <img src={verificationImage} />
          </div>
          <div className={styles.Body}>
            <h3>Gather customer feedback with an integrated physical and digital experience</h3>
            <p>
              Opinous for Restaurants provides a beautiful end to end customer feedback experience
              that starts at your physical locations with our Smart Card technology and ends with a
              seamless digital experience.
            </p>
            <br />
            <p>
              Our customisable review collection process allows you to easily collect your
              customer's feedback and link it back to customisable attributes of your choice such as
              a particular night's service or a given member of your staff.
            </p>
          </div>
        </div>
        <div className={classNames(styles.Part, styles.Reverse)}>
          <div className={styles.ImageMobile}>
            <img src={identityImage} />
          </div>
          <div className={styles.Body}>
            <h3>Protect your restaurant from malicious actors</h3>
            <p>
              When running a restaurant there will always be unhappy customers - but in online
              reviews often negative reviews are for a different restaurant or are deliberately
              false as part of a malicious act.
            </p>
            <br />
            <p>
              Opinous provides a complete toolset for professionally responding to negative reviews
              both on our platform and around the web whilst flagging false or abusive reviews for
              removal by our expert team.
            </p>
          </div>
          <div className={styles.Image}>
            <img src={identityImage} />
          </div>
        </div>
        <div className={styles.Part}>
          <div className={styles.Image}>
            <img src={celebrateImage} />
          </div>
          <div className={styles.Body}>
            <h3>Monitor and Improve your SEO with reviews</h3>
            <p>
              Opinous allows you to easily publish and display your reviews to improve your
              placement in keywords you care about and provide social validation to new customers.
            </p>
            <br />
            <p>
              Our deep technical integration with platform like Google My Business and TripAdvisor
              allows you to effortlessly view key statistics and triage issues from across the web.
            </p>
          </div>
        </div>
      </section>
      <LearnMore />
      <Footer />
    </div>
  );
};

export default UseCaseRestaurants;
